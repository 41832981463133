import * as React from "react";
import Layout from "../../components/Layout";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";

function CollapseDestinatari() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a
        onClick={() => setOpen(!open)}
        aria-controls="destinatari"
        aria-expanded={open}
        href="#destinatari"
      >
        {open ? "Nascondi" : "Continua a leggere"}
      </a>
      <Collapse in={open}>
        <div className="collapse multi-collapse" id="destinatari">
          <p>
            Destinatari della Formazione Continua devono essere{" "}
            <b>
              tutti i professionisti sanitari che direttamente operano
              nell'ambito della tutela della salute individuale e collettiva
            </b>
            . I destinatari dell'obbligo ECM sono i soggetti appartenenti ad una
            delle professioni sanitarie riconosciute dalla normativa vigente.
          </p>
          <p>
            Il sistema ECM riguarda anche i <b>liberi professionisti</b>, che
            possono trovare in esso un metodo di formazione continua e uno
            strumento di attestazione della propria costante riqualificazione
            professionale.
          </p>
          <p>
            Il programma ECM prevede l'attribuzione di un numero determinato di
            crediti formativi per ogni area specialistica medica e per tutte le
            professioni sanitarie. Il sistema ECM è strutturato in trienni; i
            crediti da acquisire ogni tre anni, anche nel triennio 2020-2022,
            sono 150.
            <br></br>
          </p>
          <p>Gli organi istituzionali che regolano il sistema ECM sono:</p>
          <ul>
            <li>
              <a
                href="https://ape.agenas.it/organi/commissione-nazionale.aspx"
                title="Commissione Nazionale"
                target="_blank"
                rel="noopener noreferrer"
              >
                Commissione Nazionale
              </a>
            </li>
            <li>
              <a
                href="https://ape.agenas.it/organi/comitato-garanzia.aspx"
                title="Comitato di Garanzia"
                target="_blank"
                rel="noopener noreferrer"
              >
                Comitato di garanzia
              </a>
            </li>
            <li>
              <a
                href="https://ape.agenas.it/organi/osservatorio.aspx"
                title="Osservatorio nazionale"
                target="_blank"
                rel="noopener noreferrer"
              >
                Osservatorio nazionale
              </a>
            </li>
            <li>
              <a
                href="https://ape.agenas.it/organi/comitato-tecnico.aspx"
                title="Comitato Tecnico Regioni"
                target="_blank"
                rel="noopener noreferrer"
              >
                Comitato Tecnico Regioni
              </a>
            </li>
            <li>
              <a
                href="https://ape.agenas.it/organi/cogeaps.aspx"
                title="Co.Ge.A.P.S."
                target="_blank"
                rel="noopener noreferrer"
              >
                Co.Ge.A.P.S.
              </a>
            </li>
            <li>
              <a
                href="https://ape.agenas.it/organi/regioni-province.aspx"
                title="Regioni e Province autonome"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regioni e Province autonome
              </a>
            </li>
          </ul>
          <p>
            <b>OBIETTIVI FORMATIVI</b>
          </p>
          <p>
            Si deve prendere come riferimento il paragrafo 2.1 (Obiettivi
            formativi) del{" "}
            <a
              href="https://ape.agenas.it/documenti/normativa/Manuale_formazione_continua_professionista_sanitario/Manuale_sulla_formazione_continua_professionista_sanitario.pdf"
              title="Manuale sulla formazione continua del professionista sanitario"
              target="_blank"
              rel="noopener noreferrer"
            >
              Manuale sulla formazione continua del professionista sanitario.
            </a>
          </p>
          <p>
            Le attività formative devono essere programmate e realizzate tenendo
            conto degli{" "}
            <b>
              obiettivi formativi previsti come prioritari nel Programma
              nazionale ECM, nel Piano sanitario nazionale e nei Piani sanitari
              regionali e delle Province autonome di Trento e Bolzano
            </b>
            .
          </p>
          <p>Gli obiettivi formativi sono raggruppati in 3 principali aree:</p>
          <ul>
            <li>
              <b>obiettivi tecnico-professionali</b>: finalizzati allo sviluppo
              delle competenze e delle conoscenze tecnico-professionali
              individuali nel settore specifico di attività; sono
              specificatamente rivolti alla professione di appartenenza o alla
              disciplina;
            </li>
            <li>
              <b>obiettivi di processo</b>: finalizzati allo sviluppo delle
              competenze e delle conoscenze nelle attività e nelle procedure
              idonee a promuovere il miglioramento della qualità, efficienza,
              efficacia, appropriatezza e sicurezza degli specifici processi di
              produzione delle attività sanitarie; si rivolgono ad operatori ed
              équipe che intervengono in un determinato segmento di produzione;
            </li>
            <li>
              <b>obiettivi di sistema</b>: finalizzati allo sviluppo delle
              conoscenze e competenze nelle attività e nelle procedure idonee a
              promuovere il miglioramento della qualità, efficienza, efficacia,
              appropriatezza e sicurezza dei sistemi sanitari. Questi obiettivi
              si rivolgono, di norma, a tutti gli operatori avendo quindi
              caratteristiche interprofessionali.
            </li>
          </ul>
        </div>
      </Collapse>
    </>
  );
}

export default function DiscentiPage() {
  return (
    <Layout pageTitle="Formazione ECM: bonus crediti e informazioni per i professionisti sanitari">
      <Helmet>
        <meta
          name="description"
          content="Formazione ECM: informazioni utili per i professionisti sanitari. Scopri la normativa, i bonus con cui ridurre il numero dei crediti, i portali dove verificare i la tua situazione formativa"
        />
      </Helmet>
      <section className="position-relative">
        <StaticImage
          src="../../images/background-fad-ecm.jpg"
          className="img-header-home"
          alt="Formazione ECM"
          title="Formazione ECM"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              title="Formazione ECM: bonus crediti e informazioni per i professionisti sanitari"
              className="text-center text-white display-3"
            >
              Formazione ECM: bonus crediti e informazioni utili per i
              professionisti sanitari
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              ECM significa Educazione Continua in Medicina Formazione.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri di più
            </a>
          </div>
        </div>
      </section>
      <div className="container" id="sezione-corsi">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2
              title="Il Mondo ECM"
              className="my-4 text-center titolo-sezione"
              style={{ color: "#DE413D" }}
            >
              IL MONDO ECM
            </h2>
            <p>
              Il sistema ECM è lo strumento per garantire la formazione continua
              finalizzata a{" "}
              <b>
                migliorare le competenze e le abilità cliniche, tecniche e
                manageriali e a supportare i comportamenti dei professionisti
                sanitari
              </b>
              , con l'obiettivo di assicurare efficacia, appropriatezza,
              sicurezza ed efficienza all'assistenza prestata dal Servizio
              Sanitario Nazionale in favore dei cittadini.
            </p>
            <p>
              L'obbligo di formazione continua decorre dal{" "}
              <b>1° gennaio successivo alla data di iscrizione all'Ordine</b>,
              da tale data il professionista sanitario deve maturare i crediti
              previsti per il triennio successivo (150). Per coloro il cui
              esercizio non era subordinato all'iscrizione ad un Ordine,
              l'obbligo decorre dal{" "}
              <b>
                1° gennaio successivo al conseguimento del titolo di studio o
                altro provvedimento abilitante.
              </b>
            </p>
            <CollapseDestinatari />
          </div>
        </div>
      </div>
      <div className="container-fluid px-5 py-3">
        <div className="row">
          <div className="col-12">
            <h2
              title="Il Mondo ECM - Bonus crediti ECM, esoneri ed esenzioni"
              className="my-4 text-center titolo-sezione"
              style={{ color: "#FFB745" }}
            >
              BONUS CREDITI ECM, ESONERI ED ESENZIONI
            </h2>
          </div>
          <div className="col-12 col-md-6" id="bonus">
            <h3 title="- Bonus crediti ECM">BONUS ECM</h3>
            <p>
              {" "}
              La Commissione Nazionale per la Formazione Continua permette ai
              discenti di ottenere i crediti ecm anche tramite{" "}
              <b>
                attività che differiscono dalla partecipazione ai corsi
                formativi
              </b>
              .
            </p>
            <p>
              Esistono <b>quattro modalità</b> per ottenere i crediti ecm e
              quindi assolvere all'obbligo formativo:
            </p>
            <ul>
              <li>
                <b>Bonus 30 crediti ECM</b>: creando il Dossier Formativo potrai
                usufruire del bonus e ricevere fino a 30 crediti ECM suddivisi
                in due trienni formativi. Scopri di più cliccando sul{" "}
                <Link
                  to="/formazione-ecm-normativa-bonus/bonusecm-dossier-formativo/"
                  title="Dossier Formativo Bonus crediti ECM"
                >
                  link
                </Link>
              </li>
              <li>
                <b>Bonus 50 crediti ECM</b>: per tutti i professionisti sanitari
                che hanno continuato a lavorare durante l'emergenza sanitaria è
                prevista una riduzione di crediti pari ad 1/3 dei totali da
                acquisire. Scopri di più cliccando sul{" "}
                <Link
                  to="/formazione-ecm-normativa-bonus/bonusecm-emergenza-sanitaria/"
                  title="Lavoro continuativo durante l'emergenza sanitaria Bonus crediti ECM"
                >
                  link
                </Link>
              </li>
              <li>
                <b>Bonus fino a 32 crediti ECM</b>: per i professionisti che
                hanno lavorato alla sperimentazione di medicinali è possibile
                ottenere fino a 32 crediti ECM. Scopri di più cliccando sul{" "}
                <Link
                  to="/formazione-ecm-normativa-bonus/bonusecm-sperimentazione-medicinali/"
                  title="Sperimentazione di medicinali Bonus crediti ECM"
                >
                  link
                </Link>
              </li>
              <li>
                <b>Bonus fino a 90 crediti ECM</b>: coloro che hanno fatto
                ricorso alla formazione individuale avranno la possibilità di
                ottenere fino al 60% dei crediti previsti dell'obbligo formativo
                triennale. Scopri di più cliccando sul{" "}
                <Link
                  to="/formazione-ecm-normativa-bonus/bonusecm-formazione-individuale/"
                  title="Formazione individuale Bonus crediti ECM"
                >
                  link
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <h3 title="Esoneri ed Esenzioni">ESONERI ED ESENZIONI</h3>
            <p>
              L'esonero è un diritto esercitabile esclusivamente su istanza del
              professionista sanitario secondo le modalità previste dal Manuale
              e costituisce una{" "}
              <b>riduzione dell'obbligo formativo individuale triennale</b>.
            </p>
            <p>
              La frequenza, in Italia o all'estero, di corsi universitari (o
              equipollenti) finalizzati allo sviluppo delle competenze dei
              professionisti sanitari dà diritto all'esonero dalla formazione
              ECM.
            </p>
            <p>
              La durata dell'esonero non può eccedere la durata legale del
              corso, e deve corrispondere al periodo di effettiva frequenza.{" "}
              <b>
                L'esonero non attribuisce crediti, ma riduce l'obbligo formativo
                individuale
              </b>
              .
            </p>
            <p>
              L'esenzione è un diritto esercitabile esclusivamente su istanza
              del professionista sanitario secondo le modalità previste dal
              Manuale e costituiscono una{" "}
              <b>riduzione dell'obbligo formativo triennale</b> le fattispecie
              di sospensione dell'attività professionale e incompatibilità con
              una regolare fruizione dell'offerta formativa attestata o
              autocertificata.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Rimani sempre aggiornato sulle ultime novità del Mondo ECM!"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi ricevere aggiornamenti sui nuovi Bonus ECM?
            </h4>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>

            <p>
              <span style={{ fontSize: 14 }}>
                I tuoi dati non verranno mai condivisi con nessun Provider!
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2
              title="Il Mondo ECM - Strumenti di verifica e controllo - Crediti ECM"
              className="my-4 text-center titolo-sezione"
              style={{ color: "#729C4F" }}
            >
              STRUMENTI DI VERIFICA E CONTROLLO
            </h2>
          </div>
          <div className="col-12 col-md-6">
            <h3 title="Strumenti di verifica e controllo - Portale MyECM">
              Portale MYECM
            </h3>
            <p>
              Il servizio{" "}
              <a
                href="https://ape.agenas.it/professionisti/myecm.aspx"
                title="Portale MyECM"
                target="_blank"
                rel="noopener noreferrer"
              >
                myECM
              </a>{" "}
              mette a disposizione di ogni professionista sanitario una pagina
              personalizzata tramite la quale è possibile{" "}
              <b>
                monitorare comodamente online la propria situazione formativa
                per i crediti acquisiti esclusivamente tramite eventi
                organizzati dai provider accreditati dalla Commissione Nazionale
                per la Formazione Continua
              </b>
              .
            </p>
            <p>Su myECM il professionista può:</p>
            <ul>
              <li>
                verificare l'ammontare dei crediti E.C.M. suddivisi per anno e
                tipologia di offerta formativa (formazione a distanza,
                residenziale e sul campo);
              </li>
              <li>
                consultare un elenco degli eventi formativi per i quali ha
                conseguito crediti, contenente tutte le informazioni relative a
                ciascuno di essi;
              </li>
              <li>
                consultare un elenco di eventi di prossimo svolgimento, filtrato
                in maniera automatica dal sistema sulla base delle discipline
                associate
              </li>
            </ul>
            <p>
              Con il servizio myECM il professionista può esprimere la propria{" "}
              <b>opinione su ogni corso frequentato</b>. Il giudizio viene
              registrato dall'ufficio ECM , in forma rigorosamente anonima, e
              contribuisce a creare una valutazione dei singoli provider.
            </p>
            <p>
              Questa valutazione sarà successivamente resa pubblica e permetterà
              sia di facilitare la scelta di eventi di qualità a tutti i
              professionisti sanitari, sia di premiare il merito dei provider
              che hanno fornito i servizi migliori.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3 title="Strumenti di verifica e controllo - Portale Co.Ge.A.P.S.">
              Portale CO.GE.A.P.S.
            </h3>
            <p>
              Il{" "}
              <a
                href="http://wp.cogeaps.it/"
                title="Portale Co.Ge.A.P.S."
                target="_blank"
                rel="noopener noreferrer"
              >
                Co.Ge.A.P.S.
              </a>{" "}
              (Consorzio Gestione Anagrafica Professioni Sanitarie) è un
              organismo che{" "}
              <b>
                riunisce le Federazioni Nazionali degli Ordini e dei Collegi e
                le Associazioni dei professionisti coinvolti nel progetto di
                Educazione Continua in Medicina
              </b>
              .
            </p>
            <p>
              Il Co.Ge.A.P.S. nasce per essere lo strumento attuativo della
              Convenzione stipulata con il Ministero della Salute che prevede la
              realizzazione di un progetto sperimentale per:
            </p>
            <ul>
              <li>la gestione e certificazione dei crediti formativi ECM;</li>
              <li>
                l istituzione di una anagrafe degli professionisti sanitari;
              </li>
              <li>
                l allestimento di un servizio tecnico permanente di
                aggiornamento dedicato ai rapporti con gli enti pubblici.
              </li>
            </ul>
            <p>
              <b>Cosa si può fare sul portale Co.Ge.A.P.S.?</b>
            </p>
            <ul>
              <li>
                Visualizzare la propria posizione anagrafica, il calcolo
                dell'obbligo formativo, i crediti formativi acquisiti a livello
                nazionale e regionale;
              </li>
              <li>Inserire esoneri, esenzioni, crediti individuali;</li>
              <li>Segnalare crediti mancanti;</li>
              <li>
                Procedere al recupero dell'obbligo formativo dei trienni
                precedenti;
              </li>
              <li>
                Costruire e gestire il dossier formativo individuale e di
                gruppo.
              </li>
            </ul>
            <p>
              Dal 1° ottobre 2021 l'accesso al portale sarà consentito solo
              tramite <b>CIE</b> (carta di identità elettronica) o <b>SPID</b>.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Rimani sempre aggiornato sulle ultime novità del Mondo ECM!"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Rimani sempre aggiornato sulle ultime novità del Mondo ECM!
            </h4>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>
            <p>
              <span style={{ fontSize: 14 }}>
                I tuoi dati non verranno mai condivisi con nessun Provider!
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-dossier-formativo/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Online_information_re_erks.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Dossier Formativo"
                  title="Bonus ECM - Dossier Formativo"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">Bonus ECM - Dossier Formativo</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-emergenza-sanitaria/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_doctor_kw5l.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Emergenza Sanitaria"
                  title="Bonus ECM - Emergenza Sanitaria"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Emergenza Sanitaria
                  </h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-formazione-individuale/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Studying_re_deca.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Formazione individuale"
                  title="Bonus ECM - Formazione individuale"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Formazione individuale
                  </h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <Link to="/formazione-ecm-normativa-bonus/bonusecm-sperimentazione-medicinali/">
              <div className="card bg-dark text-white" style={{ height: 350 }}>
                <StaticImage
                  src="../../images/bonusecm/undraw_Scientist_0ft9.png"
                  className="card-img h-100"
                  alt="Bonus ECM - Sperimentazione Medicinali"
                  title="Bonus ECM - Sperimentazione Medicinali"
                  objectFit="cover"
                  height={350}
                />
                <div className="card-img-overlay text-center">
                  <h4 className="card-title">
                    Bonus ECM - Sperimentazione Medicinali
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
